@import 'index.scss';

#otherCardLastTransactionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $separator-color;
  padding: 13px 12px 13px 35px;
  border-radius: 14px;
  margin-bottom: $small-gutter;
  cursor: pointer;
  @include tiny-device {
    padding-left: 20px;
  }
  .otherCardLastTransactionItem {
    &__desc {
      margin-left: 70px;
      max-width: 310px;
      @include tiny-device {
        margin-left: 21px;
      }
    }
  }
}
