@import 'index.scss';

.credit-card-list__item {
  &--brand {
    max-width: 26px;
  }
  &--dot {
    width: 5px;
    height: 5px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50%;
    opacity: 0.5;
    background-color: $placeholder-color;
  }
}
