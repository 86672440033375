@import 'index.scss';

#welcomeDrawer {
  display: none;

  @include regular-device {
    display: block;
  }

  .welcomeDrawer {
    &-bar {
      cursor: pointer;
    }
  }
}
body {
  .welcomeDrawer-drawer {
    min-width: 290px;
    background-image: linear-gradient(320deg, #3ac7f3 10%, $alternative-color-3);
    padding-top: 90px;

    &--smallHeightDevice {
      padding-top: 60px;
    }
    &--divider {
      @include tiny-device {
        display: none;
      }
    }

    @include tiny-device {
      width: 100%;
      padding-top: 0;
    }

    hr {
      width: 100%;
      margin: 0 auto;
      background-color: white;
      opacity: 0.15;
    }

    .MuiLink-root {
      color: white;
      font-size: $font-regular-big;
    }
    &--logo {
      display: none;
      background-color: $alternative-color-3;
      @include tiny-device {
        display: flex;
      }
    }
  }
  .locationIcon {
    color: #fff;
  }
}
