@import 'index.scss';

#login-password-step {
  .Mui-error {
    padding: 0 70px;
    @include tiny-device {
      padding: 0 50px;
    }
  }
  #reCAPTCHA {
    text-align: -webkit-center;
    div {
      width: 304px;
    }
  }
}
