@import 'index.scss';

#myAccount {
  .container {
    margin-top: 32px;
    @include regular-device {
      margin-top: $regular-gutter;
    }
  }
  .myAccount {
    &__title {
      font-size: $font-regular-big;
      color: $gray-color;
      font-weight: bold;
      margin-bottom: $regular-gutter;

      &__other {
        margin-top: 52px;
        @include tiny-device {
          margin-top: $regular-gutter;
        }
      }
      &__help {
        @include tiny-device {
          margin-top: -$regular-gutter;
        }
      }
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $separator-color;
      margin-bottom: $small-gutter;
      padding: 22px 10px 25px 16px;
      border-radius: $radius-large;
      cursor: pointer;
      &__title {
        font-size: $font-regular-big;
        text-align: left;
        font-weight: bold;
        margin-left: 16px;
        line-height: 1.2em;
        @include tiny-device {
          font-size: $font-regular;
        }
      }
    }
  }

  .helpfolderEmptyIcon {
    * {
      color: $secondary-color;
      fill: $secondary-color;
    }
  }

  svg {
    color: $secondary-color;
    width: 24px;
    height: 24px;
  }
}
