@import 'index.scss';

#cardOwner {
  margin-top: $large-gutter;
  .card--owner {
    padding: 14px 10px 16px 16px;
    background-color: $separator-color;
    border-radius: 12px;
    margin-bottom: $small-gutter;
    letter-spacing: -0.5px;
    border: 1px solid $separator-color;
    max-width: 550px;
    margin: auto;
    margin-bottom: 8px;
    cursor: pointer;
    @include tiny-device {
      margin-right: 0;
    }

    &:hover {
      border-color: $primary-color;
      @include regular-device {
        border-color: $separator-color;
      }
    }
    &__title {
      margin-bottom: 4px;
      @include tiny-device {
        font-size: $font-regular !important;
      }
    }
    &__desc {
      @include tiny-device {
        font-size: 13px !important;
      }
    }
  }
  .avatar {
    color: $dark-primary-color;
    width: 24px;
  }
  .avatarIcon {
    color: $dark-primary-color;
  }
}
