@import 'index.scss';

#cardApplication {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .userMailAdress {
    &__input {
      margin-bottom: 24px !important;
      margin-top: $big-gutter !important;
      text-align: left;
    }
  }
  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .mark-pro-placeholder {
      width: 40%; // Daha dar bir genişlik ayarladık.
      margin: 10px 0;

      @media (min-width: 768px) {
        width: 10%; // tablet ve masaüstü için daha da dar genişlik
      }
    }

    .submit-button {
      width: 40%; // Daha dar bir genişlik ayarladık.
      margin: 25px 0;

      @media (min-width: 768px) {
        width: 25%; // tablet ve masaüstü için daha da dar genişlik
      }
    }
  }
  .mark-pro-placeholder::placeholder {
    font-family: 'Mark Pro', sans-serif;
  }
  .cardApplication {
    &__item {
      padding: 12px 10px 12px 24px;
      border-radius: 12px;
      background-color: $separator-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;
      &__img {
        width: 48px;
      }
      &__first-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-transform: capitalize;
      }
    }
  }
}
