@import 'index.scss';

#informationRow {
  .informationRow {
    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 16px;
      width: 100%;
      margin: 0 auto;
      &__area {
        display: flex;
        flex: 1;
      }
    }
    &__divider {
      height: 2px;
      background-color: $separator-color;
    }
  }
}
