@import 'index.scss';

#cardApplicationUpdateSuccess {
  width: 450px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include tiny-device {
    width: auto;
  }
  .cardApplicationUpdateSuccess {
    &__card-area {
      width: 100px;
      height: 154px;
      margin-top: $large-gutter;
      position: relative;
      &__img {
        width: 100px !important;
        height: 154px !important;
      }
      &__success {
        width: 72px !important;
        height: 72px !important;
        position: absolute;
        background-color: #fff;
        border-radius: 36px;
        right: -36px;
        top: -36px;
      }
      &__copy {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
}
