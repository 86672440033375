@import 'index.scss';

.applicationsCenterDialog__item {
  padding: 12px 18px;
  border-top: 2px solid $separator-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__desc {
    text-transform: capitalize;
  }
}
