@import 'index.scss';

#cardsDetailTooltip {
  max-width: 234px;
  padding: $regular-gutter 0;
  font-size: 13px;
  text-align: center;
  background-color: $tooltip-color;
  width: 100%;
  color: #fff;
  display: block;
  border-radius: $radius-large;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
