@import 'index.scss';

#dashboardLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .dashboardLayout {
    &-childrenContainer {
      position: relative;
      margin-bottom: $large-gutter;
      margin-top: $large-gutter;

      @include regular-device {
        margin-bottom: $big-gutter;
        margin-top: $big-gutter;
      }

      @include tiny-device {
        margin-top: $regular-gutter;
      }

      &__back {
        position: absolute;
        margin-left: 0;
        cursor: pointer;
      }
    }
  }
}
