@import 'index.scss';

#profile {
  cursor: pointer;
  @include tiny-device {
    display: none;
  }
  .avatar {
    max-width: 40px;
    margin-right: 5px;
  }

  .profileAvatar {
    &--blue {
      background-color: rgba(56, 60, 82, 0.3);
      padding: $small-gutter;
      border-radius: $radius-regular;
      .user--name {
        color: #fff;
        text-transform: lowercase;
        &:first-letter {
          text-transform: capitalize;
        }
        &:first-child {
          margin-right: 5px;
        }
      }
      .avatar {
        color: #fff;
      }
      &:hover {
        background-color: rgba(56, 60, 82, 0.5);
      }
    }
    &--white {
      background-color: #fff;
      padding: $small-gutter;
      border-radius: $radius-regular;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);

      .user--name {
        color: $secondary-color;
        text-transform: capitalize;
        &:first-child {
          margin-right: 5px;
        }
      }
      .avatar {
        color: $secondary-color;
      }
      .MuiTypography-colorPrimary {
        color: $placeholder-color;
        font-weight: bold !important;
      }
      &:hover {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
}
