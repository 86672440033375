@import 'index.scss';

#agreements {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }

  .agreements {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $small-gutter;
      padding: 15px 10px 17px 16px;
      background-color: $separator-color;
      border-radius: $radius-large;
      cursor: pointer;
    }
    &__title {
      margin-bottom: $large-gutter;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
