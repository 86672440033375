@import 'index.scss';

#explicitConsentConfirm {
  width: 550px;
  margin: 0 auto;

  @include tiny-device {
    width: auto;
  }

  .explicitConsentConfirm {
    &__checkBorder {
      padding: 12px 16px 12px 16px;
      border-radius: $radius-large;
      border: 2px solid $separator-color;
      cursor: pointer;
      .Mui-checked {
        .MuiSvgIcon-root {
          fill: $primary-color;
          border-radius: 6px !important;
        }
      }
      .MuiSvgIcon-root {
        fill: $disabled-color;
        border-radius: 6px !important;
      }
    }
  }
}
