@import 'index.scss';

#welcomeContainer {
  min-height: calc(100vh - 90px);
  padding-left: 155px;

  @include regular-device {
    min-height: unset;
    padding-right: $big-gutter;
    padding-left: $big-gutter;
  }

  @include tiny-device {
    padding-right: $regular-gutter;
    padding-left: $regular-gutter;
  }

  .welcomeContainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;

    @include regular-device {
      height: 280px;
      margin-right: -$big-gutter;
      margin-left: -$big-gutter;
      margin-top: 110px;
      width: calc(100% + 60px);
    }

    @include tiny-device {
      margin-top: $large-gutter;
      height: 180px;
      margin-right: -$regular-gutter;
      margin-left: -$regular-gutter;
    }

    &-bg {
      background-image: linear-gradient(320deg, #55d3ff 25%, #007fb7);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -2;
    }

    &-img {
      position: absolute;
      left: -50px;
      top: 40px;
      z-index: -1;
      max-width: 750px;

      @include regular-device {
        top: -120px;
        height: 500px;
        left: 0;
        right: 0;
        margin: auto;
      }

      @include tiny-device {
        height: 256px;
        transform: unset;
        top: -35px;
      }
    }
  }
}
