@import 'index.scss';

#news-item {
  cursor: pointer;
  height: auto;
  border-radius: 3px;
  color: white;
  position: relative;
  padding: 5px;

  .news-item {
    &__title {
      font-weight: bold;
      color: #fff;
      font-size: $font-regular-big;
      position: absolute;
      left: 20px;
    }
    &__top {
      top: 20px;
    }
    &__bottom {
      bottom: 20px;
    }
  }
  img {
    max-width: 100%;
    border-radius: 14px;
  }
}
