@import 'index.scss';

#emailValidation {
  .emailValidation {
    &__header {
      padding: $big-gutter 0;
      border-bottom: 1px solid $light-gray-color;
      @include tiny-device {
        text-align: center;
      }
    }
    &__icon {
      margin-bottom: $regular-gutter;
    }
  }
  .semi-container {
    text-align: center;
    padding-top: 120px;
    @include small-device {
      padding-top: 80px;
    }
    @include tiny-device {
      padding-top: 24px;
    }
  }
  .greenTickBigIcon {
    width: 120px;
    height: 120px;
    margin-bottom: $big-gutter;
  }
  .errorCircleIcon {
    margin-bottom: $big-gutter;
  }
}
