@import 'index.scss';

#multistepProgress {
  display: flex;
  justify-content: center;
  margin-bottom: $bigger-gutter;

  .multistepProgress {
    &-item {
      display: flex;

      &__inner {
        width: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-circle {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin-bottom: 8px;

          &--onProgress {
            background: $primary-color;
          }
          &--done {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-top: -5px;
          }
          &--next {
            background-color: $light-gray-color;
          }
        }
      }
      &__separator {        
        width: 60px;
        height: 2px;
        margin-top: 3px;
        margin-left: -2px;
        background-color: $separator-color;
        &.mini {
          width: 40px;
        }
      }
    }
  }
}
