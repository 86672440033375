@import 'index.scss';

#faq {
  width: 940px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  @include regular-device {
    width: auto;
  }
  .closeIcon {
    top: 12px !important;
  }
  .arrowLeftIcon {
    top: 25px;
    left: 55px;

    @include tiny-device {
      top: 5px !important;
    }
    @include regular-device {
      left: 15px;
    }
  }
}
