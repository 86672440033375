@import 'index.scss';

#lotteryForm {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  input {
    text-transform: capitalize;
    cursor: pointer;
    -webkit-text-fill-color: #383c52;
    -webkit-opacity: 1;
    color: #383c52;
    //background: white;
  }
  .lotteryForm {
    &__title {
      margin-bottom: $small-gutter;
      font-weight: bold;
      margin-top: 30px;
    }
    &__content {
      margin-bottom: $large-gutter;
    }
    &--disabled {
      input,
      textarea {
        background-color: #f5f5f6;
        border-radius: 12px;
      }
    }
    &__postCode {
      .MuiInputBase-input.Mui-disabled {
        cursor: text;
      }
    }
    &__adressDetail {
      .MuiFilledInput-multiline {
        padding: 0;
      }
      textarea {
        padding: 20px 14px 7px 14px !important;
      }
    }
    &__postCode {
      input {
        cursor: inherit;
      }
    }
  }
  .chevronDown {
    position: absolute;
    right: 8px;
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent !important;
  }
}
@media screen and (max-width: 768px) {
  #lotteryForm {
    padding: 0 20px;
  }
}

#lottery_success {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #lottery_success {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
