@import 'index.scss';

#permissions {
  width: 550px;
  margin: 0 auto;

  @include tiny-device {
    width: auto;
  }

  .permission {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $separator-color;
      margin-bottom: $small-gutter;
      padding: 22px 10px 25px 16px;
      border-radius: $radius-large;
      cursor: pointer;
      &__title {
        font-size: $font-regular-big;
        font-weight: bold;
        margin-left: 16px;
        @include tiny-device {
          font-size: $font-regular;
        }
      }
    }
  }
}
