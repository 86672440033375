@import 'index.scss';

#inputOneCharBox {
  margin-bottom: $big-gutter;
  text-align: center;
  .inputOneCharBox {
    &-container {
      display: flex;
      justify-content: center;
      position: relative;

      &__input {
        font-family: 'Mark Pro';
        width: 36px;
        height: 36px;
        margin: 0 5px;
        border-radius: 10px;
        border: solid 2px $light-gray-color;
        font-size: $font-regular-big;
        color: $input-color;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tiny-device {
          width: 28px;
          height: 28px;
          margin: 0 4px;
        }

        &--filled {
          border-color: $input-color;
        }

        &--hide {
          font-size: 24px;
          padding-top: 5px;
          padding-bottom: 7px;
          font-family: Verdana, sans-serif !important;
          letter-spacing: 1px;
        }

        &--xSmall {
          @include tiny-device {
            width: 24px;
            height: 24px;
            margin: 0 2px;
          }
        }

        &--disabled {
          background-color: $white-gray-color;
        }

        &--active {
          border-color: $primary-color;
        }

        &:focus {
          outline: none;
        }
      }

      &__showPassword {
        padding-top: $regular-gutter;
        color: $primary-color;

        span {
          cursor: pointer;
        }
      }
    }
  }
}
