@import 'index.scss';

#custom-tooltip {
  position: absolute;
  display: flex;
  align-self: center;
  padding: 19px 56px 21px 63px;
  border-radius: 14px;
  background-color: #888a97;
  color: #fff;
  font-size: $font-smaller;
}
