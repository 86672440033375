@import 'index.scss';

#faqFooter {
  width: 280px;
  margin: 0 auto;
  margin-top: $large-gutter;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .faqFooter {
    &__btn {
      width: 280px;
      padding: 14px 0px 14px 16px;
      margin-top: $regular-gutter;
      border-radius: 14px;
      border: solid 2px $light-gray-color;
      position: relative;
      display: flex;
      cursor: pointer;

      @include tiny-device {
        margin-bottom: $regular-gutter;
      }

      &__icon {
        width: 24px;
        height: 24px;
        margin: 0 8px 0 0;
        object-fit: contain;
      }
    }
  }
}
