@import 'index.scss';

#organisationStep {
  input {
    text-transform: capitalize;
    cursor: pointer;
  }
  svg {
    position: absolute;
    right: 8px;
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent !important;
  }
  .organisationStep {
  }
}
