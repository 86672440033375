@import 'index.scss';

.cardTransactionHistoryEmpty {
  &__container {
    width: 312px;
    @include tiny-device {
      width: auto;
    }
  }
}
