@import 'index.scss';

#virtualCard-information {
  border-radius: $radius-large;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  justify-content: flex-start;
  background-color: #f2f4f6;
}
