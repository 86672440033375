@import 'index.scss';

#cardItem {
  margin-top: $big-gutter;
  cursor: pointer;
  position: relative;
  @include tiny-device {
    margin-top: $small-gutter;
  }
  .card-item {
    &__waiting-card {
      position: absolute;
      right: -5px;
      top: 5px;
      z-index: 99;
      &-wrapper {
        position: relative;
      }
      &-desc {
        position: absolute;
        left: 4px;
        top: 0;
        padding: 2px;

        &__number {
          color: $toast-error-text;
          padding: 3px 4.5px;
          background-color: #fff;
          border-radius: 100%;
          margin-right: 3px;
          font-weight: bold;
        }
      }
      &-desc-passive {
        position: absolute;
        left: 4px;
        padding: 1px;
      }
    }
    &__container {
      border-radius: $radius-large;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-content: center;
      img {
        max-width: 66px;
        margin-top: -35px;
        max-height: 102px;
      }
    }

    &__blue {
      background-color: $blue-card-item-color;
    }
    &__red {
      background-color: $red-card-item-color;
    }
    &__green {
      background-color: $green-card-item-color;
    }
    &__orange {
      background-color: $orange-card-item-color;
    }
    &__darkblue {
      background-color: $darkblue-card-item-color;
    }
    &__digital {
      background-color: $digital-card-item-color;
    }
    &__city {
      background-color: $city-card-item-color;
    }
    &__not--active {
      opacity: 0.5;
    }
  }
}
