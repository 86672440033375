@import 'index.scss';

#ibmMap {
  min-height: 30vh;
  z-index: 99999999999;
}
#marker {
  .marker {
    &__unSelected {
      width: 31px;
      height: 37px;
      margin-bottom: 15.5px;
      position: absolute;
      transform: translate(-50%, -100%);
    }
    &__selected {
      width: 45px;
      height: 54px;
      margin-bottom: 27px !important;
      position: absolute;
      transform: translate(-50%, -100%);
    }
  }
}
