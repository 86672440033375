@import 'index.scss';

#welcomeSignIn {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include regular-device {
    padding-top: 15px;
  }

  .welcomeSignIn {
    &-title {
      margin-bottom: $large-gutter;

      @include big-device {
        max-width: 510px;
      }
      @include regular-device {
        margin-bottom: $regular-gutter;
      }

      @include tiny-device {
        font-size: $font-big;
      }
    }

    &-desc {
      margin-bottom: $large-gutter;
      @include regular-device {
        margin-bottom: $big-gutter;
      }
    }

    &-inputContainer {
      width: 70%;
      @include regular-device {
        width: 50%;
      }

      @include tiny-device {
        width: 100%;
      }
    }

    &-button {
      margin-left: $regular-gutter;
      padding: 0;
      height: 50px;
      width: 25%;

      @include tiny-device {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
