@import 'index.scss';

#pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .pagination {
    &__item {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      margin-left: 8px;
      border: solid 2px $separator-color;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
