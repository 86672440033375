@import 'index.scss';

#addCard {
  margin-top: $large-gutter;

  @include tiny-device {
    margin-top: $regular-gutter;
  }
  .dialogWithActions-textContainer {
    margin-bottom: $large-gutter !important;
  }
  .MuiInputAdornment-root {
    position: absolute;
    right: 14px;
  }
  .MuiGrid-grid-xs-12 {
    @include tiny-device {
      padding-top: $regular-gutter;
    }
  }
  .add-card {
    &__radio-button {
      .MuiFormControlLabel-root {
        margin-left: -11px !important;
      }
      .MuiFormControlLabel-label {
        font-weight: bold;
        color: $secondary-color;
        font-size: 13px !important;
      }
      &--second {
        margin-left: 0px;
        @include tiny-device {
          margin-left: 0;
        }
      }
      /* .MuiRadio-root {
        color: $disabled-color;
      } */
    }
  }
}
