@import 'index.scss';

#social-media {
  width: 100%;
  margin-top: $large-gutter;
  @include tiny-device {
    margin-top: $small-gutter;
  }
  .social-media {
    &__title {
      text-align: left !important;
    }
    &__icon {
      margin-left: 9px;
      cursor: pointer;
    }
    &__insta {
      margin-left: 0px !important;
    }
  }
}
