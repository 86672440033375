@import 'index.scss';

#userInfo {
  max-width: 550px;
  margin: auto;
  .userInfo {
    &__title {
      margin-bottom: $large-gutter;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
