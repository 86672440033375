@import 'index.scss';

#deliveryStep {
  .deliveryStep {
    &__check {
      display: flex;
      align-items: center;
      margin-bottom: $small-gutter;
      padding: 14px 16px 14px 16px;
      border-radius: $radius-large;
      border: solid 2px $separator-color;
      cursor: pointer;
      background-color: $separator-color;
      &__col {
        margin-left: $small-gutter;
        text-align: left;
      }
    }
    &__btn {
      width: 280px !important;
      margin: 0 auto;
      display: flex;
    }
  }
}
