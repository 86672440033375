@import 'index.scss';

#otherCardDetailsHeader {
  @include tiny-device {
    margin-bottom: 40px;
  }
  .otherCardDetailsHeader {
    &__container {
      max-width: 960px;
      padding-left: $big-gutter;
      margin-left: auto;
      margin-right: auto;
      padding-top: $big-gutter;
      padding-bottom: $big-gutter;
      position: relative;
      min-height: 130px;
      @include regular-device {
        padding-right: $regular-gutter;
        padding-top: $large-gutter;
      }

      @include tiny-device {
        padding: $regular-gutter $small-gutter 0 $small-gutter;
        min-height: 180px;
      }
    }
    &__img {
      max-width: 264px;
      position: absolute;
      right: 20px;
      top: 30px;
      @include small-device {
        max-width: 166px;
      }
      @include tiny-device {
        max-width: 130px;
        right: 10px;
        top: 80px;
      }
    }
    &__backIcon {
      position: absolute;
      color: #fff;
      left: -65px !important;
      cursor: pointer;
      top: 30px;
      @include regular-device {
        left: 25px !important;
        top: 15px;
      }
      @include tiny-device {
        left: 10px !important;
        top: 20px;
      }
    }

    &__item {
      margin-bottom: $regular-gutter;
    }
    &__balance {
      font-size: 44px !important;
      @include tiny-device {
        font-size: 30px !important;
      }
    }
    &__title {
      font-weight: bold;
      font-size: 21px;
      color: #fff;
      margin-bottom: $big-gutter;
      @include tiny-device {
        font-size: $font-regular-big;
        text-align: center;
        margin-bottom: $regular-gutter;
        width: 100%;
      }
    }
    &__inner-container {
      max-width: 560px;
      width: 100%;
      @include tiny-device {
        min-height: 180px;
        align-items: flex-end;
      }
    }

    &__cardinfo-desc {
      font-weight: 500;
      font-size: $font-regular;
      color: #fff;
      opacity: 0.45;
      margin-bottom: 5px;
      @include tiny-device {
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    &__cardinfo-value {
      font-weight: bold;
      font-size: 21px;
      color: #fff;
      @include tiny-device {
        font-size: 13px;
      }
    }

    &__red {
      background-image: linear-gradient(278deg, $card-detail-header-linear-red-1 10%, $card-detail-header-linear-red-2);
      img {
        filter: drop-shadow(2px 2px 5px $red-card-shadow-color);
      }
    }
    &__green {
      background-image: linear-gradient(
        275deg,
        $card-detail-header-linear-green-1 10%,
        $card-detail-header-linear-green-2 80%
      );
      img {
        filter: drop-shadow(2px 2px 5px $green-card-shadow-color);
      }
    }
    &__orange {
      background-image: linear-gradient(
        277deg,
        $card-detail-header-linear-orange-1,
        $card-detail-header-linear-orange-2
      );
      img {
        filter: drop-shadow(2px 2px 5px $orange-card-shadow-color);
      }
    }
    &__darkblue {
      background-image: linear-gradient(-45deg, #3493ff 0%, #3458e7 100%);

      img {
        filter: drop-shadow(2px 2px 5px $darkblue-card-shadow-color);
      }
    }
    &__blue {
      background-image: linear-gradient(
        280deg,
        $card-detail-header-linear-blue-1 10%,
        $card-detail-header-linear-blue-2
      );
      img {
        filter: drop-shadow(2px 2px 5px $blue-card-shadow-color);
      }
    }
    &__city {
      background-image: linear-gradient(
        280deg,
        $card-detail-header-linear-city-1 10%,
        $card-detail-header-linear-city-2
      );
      img {
        filter: drop-shadow(2px 2px 5px $city-card-shadow-color);
      }
    }
    &__digital {
      background-image: linear-gradient(
        280deg,
        $card-detail-header-linear-digital-1 10%,
        $card-detail-header-linear-digital-2
      );
      img {
        filter: drop-shadow(2px 2px 5px $digital-card-shadow-color);
      }
    }
  }
  .copyIcon {
    color: #fff !important;
    margin-left: $small-gutter;
    cursor: pointer;
    @include tiny-device {
      margin-left: 2px;
    }
  }
  .MuiTooltip-tooltip {
    background-color: $tooltip-color;
    font-size: 13px;
    color: #fff;
    padding: $regular-gutter 0;
    max-width: 56px;
    width: 100%;
    display: block;
  }
  .settingsIcon {
    position: absolute;
    right: 300px;
    top: 30px;
    cursor: pointer;
    @include small-device {
      right: 200px;
    }
    @include tiny-device {
      right: 5px;
      top: 45px;
    }
  }
}
