@import 'index.scss';

#login {
  .login-password-desc {
    @include tiny-device {
      font-size: 14px;
    }
  }
  Button:disabled {
    opacity: 0.5;
  }
  #reCAPTCHA {
    text-align: -webkit-center;
    margin-bottom: 20px;
    div {
      width: 304px;
    }
  }
  .clarification-text {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    flex: 1px;
    min-height: 80px;
    &.step1 {
      margin-bottom: 80px;
      justify-content: flex-end;
      @include tiny-device {
        margin-bottom: 32px;
      }
    }
  }
}
