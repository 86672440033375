@import 'index.scss';

#infoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px 14px 16px;
  border: 2px solid $separator-color;
  border-radius: 14px;
  margin-bottom: $small-gutter;
  .infoItem {
    &__title {
      font-size: 13px;
      margin-bottom: 5px;
      color: $checkbox-label-color;
    }
    &__desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__edit {
      font-size: 13px;
      text-align: right;
    }
  }
}
