@import 'index.scss';

#storeLinks {
  &.storeLinks {
    &--onlyDesktop {
      @include regular-device {
        display: none;
      }
    }
    &--onlyMobile {
      display: none;
      @include regular-device {
        display: block;
        padding-top: 85px;
        padding-left: $regular-gutter;
        padding-right: $regular-gutter;
      }

      @include tiny-device {
        padding-top: 35px;
        margin-bottom: $big-gutter;
      }
    }
  }
  .storeLinks {
    &-imageContainer {
      display: flex;
      flex-wrap: wrap;

      img {
        cursor: pointer;
        &:nth-child(2) {
          margin-right: $small-gutter;
          margin-left: $small-gutter;
          @media (max-width: 450px) {
            margin-right: 0;
          }
        }

        @include tiny-device {
          margin-bottom: $small-gutter;
        }
      }
    }
  }
}
