@import 'index.scss';

#applicationCenters {
  .applicationCenters {
    &__default-pick {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 10px 14px 10px;
      border-radius: 12px;
      background-color: $separator-color;
      cursor: pointer;
    }
    &__adress-detail {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    &__disable {
      background-color: $disabled-color !important;
    }
  }
  .locationIcon {
    color: unset;
  }
}
