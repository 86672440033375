@import 'index.scss';

#adressList {
  .adressList {
    &__default-pick {
      display: flex;
      padding: 14px 10px 14px 10px;
      border-radius: 12px;
      background-color: $separator-color;
      cursor: pointer;
      align-items: center;
    }

    &__adress-detail {
      flex: 1;

      &__desc {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .locationIcon {
    color: unset;
  }
}
