@import 'index.scss';

#dialogUserJobs {
  .MuiDialog-paperWidthSm {
    max-width: 560px;
  }
  .MuiDialog-paperScrollPaper {
    overflow-x: hidden;
  }
  .MuiDialogTitle-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    @include tiny-device {
      padding: 0;
    }
  }
  .MuiDialog-paperScrollPaper {
    height: 100%;
    padding: 0 $small-gutter;
    @include tiny-device {
      padding: 0;
    }
  }
  .dialogUserJobs {
    &-search {
      width: 460px;
      align-items: center;
      margin: 64px auto 0 auto;
      @include tiny-device {
        width: 95%;
      }
    }
    &-container {
      padding: 6px $regular-gutter $regular-gutter $regular-gutter;
      width: 460px;
      min-height: 50vh;
      position: relative;
      @include tiny-device {
        width: unset;
      }
      &__list {
        list-style-type: none;
        padding-left: 0;
        margin-left: 0;
        li {
          cursor: pointer;
          &:hover {
            background-color: $separator-color;
            border-radius: $radius-large;
          }
          .dialogUserJobs-container__list-item {
            padding: 15px 5px 17px 16px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;
            &--selected {
              .input-text {
                color: $primary-color;
              }
            }
            @include tiny-device {
              padding: 13px 0 13px 0;
            }
          }
        }
      }
    }
  }
}
