@import 'index.scss';

#fullPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/Assets/Images/hearth-bg.png') no-repeat;
  background-size: 510px;
  background-position-x: right;
  background-position-y: bottom;
  background-attachment: fixed;

  @include tiny-device {
    padding-left: 10px;
    padding-right: 10px;
    background-size: 75%;
    background-attachment: initial;
  }
}
