body {
  margin: 0;
  font-family: 'Mark Pro';
  overflow-x: hidden;

  *:focus,
  *:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    /* -webkit-user-select: text;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -moz-user-select: text;
    user-select: auto;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  input[type='password'] {
    font-family: Verdana, sans-serif !important;
    letter-spacing: 1px;
  }
  input[type='text'],
  input,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .buttonWhite {
    background-color: white;
    border-color: $light-gray-color;
    border-width: 2px;
    color: $secondary-color;
    font-weight: bold;

    &:hover {
      background-color: inherit;
    }
  }

  // TOASTIFY

  .Toastify__toast {
    border-radius: 14px;
    padding: 0 15px !important;
    box-shadow: none;
    top: 10px;
    min-height: 56px;
    &-container {
      min-width: 344px;
      @include tiny-device {
        margin-top: 30px;
        max-width: 300px;
        min-width: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
        top: $big-gutter;
      }
    }

    &--success {
      background-color: $toast-success-text;
      span {
        color: #fff;
      }
    }
    &--warning {
      background-color: $toast-error;
      span {
        color: $toast-error-text !important;
        font-weight: bold;
      }
    }
  }

  // MATERIAL UI

  .MuiCheckbox-colorPrimary.Mui-checked:hover,
  .MuiFilledInput-root:hover {
    background-color: transparent;
  }

  .MuiInputBase-input:focus {
    border-color: $primary-color;
  }

  .MuiTypography-h5 {
    @include tiny-device {
      font-size: $font-regular-big;
    }
  }

  .MuiTypography-h6 {
    @include tiny-device {
      font-size: $font-small;
    }
  }

  .MuiDialog-paper {
    @include tiny-device {
      margin: 45px 10px;
      width: 100%;
    }
  }

  .MuiCheckbox-root.Mui-focusVisible {
    color: $primary-color;
  }

  .MuiButton-root.Mui-focusVisible {
    border-color: $primary-color;
  }

  .MuiFormLabel-root.Mui-error {
    color: $error-border-color;

    + div input {
      background-color: $error-bg-color;
      border-color: $error-border-color;
      color: $error-text-color;
    }
    + div textarea {
      background-color: $error-bg-color;
      border-color: $error-border-color;
      color: $error-text-color;
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent;
  }

  // GENERAL CLASSSES

  .flexAlignCenter {
    display: flex;
    align-items: center;
  }

  .iconRightPadding {
    padding-right: 12px;
  }

  .overflowUnset {
    overflow: unset;
  }

  // ICONS

  .arrowDownIcon {
    transform: rotate(-90deg);
  }

  .arrowLeftIcon {
    position: absolute;
    margin-left: -25px;
    margin-top: -15px;
    cursor: pointer;
    color: #000;
    @include tiny-device {
      margin-left: 0;
      margin-top: 0;
      left: 10px;
      top: 10px;
    }
  }

  .searchIcon {
    position: absolute;
    z-index: 2;
    right: 14px;
  }

  .passwordShowHideIcon {
    position: absolute;
    z-index: 2;
    right: 14px;
    cursor: pointer;
  }

  .greenTickBigIcon {
    display: block;
    margin: auto;
  }

  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
  }

  .loginIcon {
    margin-left: 10px;
  }

  .closeIconWhite {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
  }

  .handIcon {
    width: 30px;
    height: 30px;
  }
}

/// MUI DIALOG

.dialogContainer {
  padding: 60px 50px 50px 50px;
  width: 460px;

  hr {
    color: $separator-color !important;
    opacity: 0.7;
  }

  @include tiny-device {
    width: auto;
    padding: 40px 15px 20px 20px;
  }
  .selectedCard__container--active,
  .selectedCreditCard__container--active {
    border: 2px solid $secondary-color !important;
    @include tiny-device {
      .tickIcon {
        position: absolute;
        right: 8px;
        top: 5px;
      }
    }
  }
  #selectedCard {
    .tickIcon {
      color: $secondary-color !important;
    }
  }
}
.chooseCardCreditCard__dialog {
  .tickIcon {
    color: $secondary-color !important;
  }
}

.waitingInstructions-dialog {
  border-radius: 23px;
}
// LOGOS

.istanbulDarkLogo,
.istanbulKartLogo,
.istanbulWhiteLogo {
  cursor: pointer;
}
// General css for tooltip and dialog
.chooseCard__dialog--addButton {
  border: 2px solid $light-gray-color;
  display: flex;
  align-items: center;
  border-radius: 14px;
  margin-top: $regular-gutter;
  padding: 11px 48px 12px 16px;
  cursor: pointer;
}
.chooseCardCreditCard__dialog--addButton {
  border: 2px solid $light-gray-color;
  display: flex;
  align-items: center;
  border-radius: 14px;
  margin-top: $large-gutter;
  padding: 11px 48px 12px 16px;
  cursor: pointer;
}

.chooseCardCreditCard__dialog__container,
.chooseCard__dialog__container {
  overflow-y: scroll;
  max-height: 310px;
  &::-webkit-scrollbar {
    width: 1px;
  }
}

#user-card-info-item__tooltip {
  .tooltipComp__desc1 {
    margin-bottom: $small-gutter;
  }
}

.userMailAdress {
  &__input {
    margin-bottom: 24px !important;
    margin-top: $big-gutter !important;
    text-align: left;
  }
}
.userPhoneNumber__input {
  text-align: left;
  margin-bottom: 24px !important;
  margin-top: $large-gutter !important;
}
.adressListDialog {
  &__adressDetail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
