@import 'index.scss';

#news-and-campaigns {
  margin-top: $large-gutter;
  margin-bottom: $large-gutter;
  @include regular-device {
    margin-top: $regular-gutter;
  }
  @include tiny-device {
    margin-top: $big-gutter;
  }

  .tabs {
    margin-bottom: $regular-gutter;
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tab-item {
    color: $dark-gray-color;
    margin-right: $regular-gutter;
    padding: 5px 12px;
    border-radius: 10px;
    font-size: $font-regular-big;
    font-weight: bold;
    &:hover {
      color: $secondary-color;
    }
    @include tiny-device {
      font-size: $font-small;
      margin-right: 0;
    }
    &--active {
      color: #fff;
      background-color: $secondary-color;
      &:hover {
        color: #fff;
      }
    }
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
}
