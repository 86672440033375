@import 'index.scss';

#masterpass-area {
  border-radius: 8px;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: $big-gutter;
  padding: 26px 16px 26px 16px;
  background-color: $masterpass-orange-color;
  @include tiny-device {
    margin-top: $regular-gutter;
  }
}
