@import 'index.scss';

#organisationStep {
  .organisationStep {
    &--disabled {
      input,
      textarea {
       // background-color: #f5f5f6;
        border-radius: 12px;
      }
    }
  }
}
