@import 'index.scss';

#mapMask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $secondary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.85;
  z-index: 999;
  color: #fff;
  text-align: center;

  .mapMask {
    width: 820px;
    margin: 0 auto;
    padding: 0 calc(2%) 0 calc(2%);

    @include tiny-device {
      width: auto;
    }
    @include regular-device {
      width: auto;
    }

    &__nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      padding: 0 calc(10%) 0 calc(5%);
      margin-top: $large-gutter;

      @include tiny-device {
        padding: 0 calc(5%) 0 calc(5%);
      }
    }

    &__item-area {
      display: flex;
      flex-direction: row;
    }

    &__item-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: $small-gutter;
      padding: 4px $small-gutter 4px $small-gutter;
      cursor: pointer;
      margin-left: $small-gutter;
      font-size: 13px;
      margin-top: $small-gutter;
      font-weight: 600;
      color: $secondary-color;
      height: 20px;
      &__icon {
        width: $regular-gutter;
        height: $regular-gutter;
        margin-right: 6px;
      }
    }
  }
}
