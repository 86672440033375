@import 'index.scss';

#input-phone {
  display: flex;
  align-items: flex-start;

  .input-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px $light-gray-color solid;
    padding: 0px 35px 0px 13px;
    height: 46px;
    border-right: 0;
    border-top-left-radius: $radius-large;
    border-bottom-left-radius: $radius-large;
    cursor: pointer;
    position: relative;

    svg {
      position: absolute;
      right: 4px;
    }
  }
  .MuiFilledInput-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .MuiFormHelperText-root {
    @include tiny-device {
      margin-left: -70px;
    }
  }
}
