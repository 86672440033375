@import 'index.scss';
.dialogContainer {
  .calendarIcon {
    position: absolute;
    z-index: 2;
  }
  .closeCircleIcon {
    position: absolute;
    z-index: 2;
    right: 10px;
  }
  .MuiFilledInput-adornedStart {
    padding-left: 0px !important;
  }
  .MuiInputAdornment-root {
    width: 0px;
    position: absolute;
    right: 22px;
  }
  .MuiIconButton-root {
    padding: 0 !important;
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: none;
  }
}
