@import 'index.scss';

#cardVisual {
  background-color: $separator-color;
  border-radius: $radius-large;
  padding: 16px 16px 30px 16px;
  @include tiny-device {
    padding-bottom: 20px;
    margin-top: -14px;
  }
  .card-visual {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__nav {
      margin-bottom: $regular-gutter;

      button {
        margin-right: 16px;
        border: none;
        font-size: $font-small;
        padding: 4px 12px;
        font-weight: bold;
        border-radius: $radius-regular;
        background-color: transparent;
        color: $dark-gray-color;
        cursor: pointer;
        @include tiny-device {
          margin-right: 0;
        }
      }
      &-active {
        background-color: #fff !important;
        color: $secondary-color !important;
        padding: 4px 12px;
        font-weight: bold;
        font-size: $font-small;
      }
    }
    &__img {
      img {
        max-height: 180px;
        height: 100%;
        @include tiny-device {
          width: 100%;
        }
      }
    }
  }
}
