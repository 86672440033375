@import 'index.scss';

#change-language {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .change-language {
    &__check {
      display: flex;
      align-items: center;
      margin-bottom: $small-gutter;
      padding: 12px 0px 12px 16px;
      border-radius: $radius-large;
      border: solid 2px $separator-color;
      cursor: pointer;
      .MuiIconButton-root {
        padding: 0;
      }
    }
  }
}
