@import 'index.scss';

.stepWrapper {
  @include card-shadow;
  background-color: white;
  width: 460px;
  padding: $large-gutter;

  @include tiny-device {
    width: calc(100% - 40px);
    padding: 30px 20px;
    margin-bottom: $large-gutter;
  }
}
