@import 'index.scss';

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 550px;
  align-self: center;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
}
