@import 'index.scss';

#news {
  max-width: 750px;
  margin: auto;

  .news {
    &__title {
      font-size: $font-bigger;
      letter-spacing: -1px;
      margin-bottom: $regular-gutter;
    }

    &__showcase {
      border-radius: 14px;
      width: 100%;
    }
  }
}
