@import 'index.scss';

#transactions {
  .transactions {
    &__title {
      font-size: $font-regular-big;
      color: $gray-color;
      font-weight: bold;
      margin-bottom: $regular-gutter;
      @include tiny-device {
        margin-bottom: $small-gutter;
      }
    }
    &__others {
      margin-top: $large-gutter;
      @include small-device {
        margin-top: $big-gutter;
      }
      @include tiny-device {
        margin-top: $regular-gutter;
      }
    }
    &__item {
      background-color: $separator-color;
      border-radius: $radius-large;
      padding: $regular-gutter 0;
      cursor: pointer;
      &__title {
        margin-top: 11px;
        @include small-device {
          font-size: 13px !important;
          margin-top: 5px;
        }
      }
    }
    &__mobileTitle {
      display: none;
      @include tiny-device {
        display: block;
      }
    }
  }
  .arrowUpIcon,
  .bigPlusIcon,
  .abonmanIcon,
  .abonmanCardIcon,
  .timeIcon,
  .bigTransactionIcon {
    color: $dark-primary-color;
  }
}
