@import 'index.scss';

#campaigns {
  max-width: 750px;
  margin: auto;
  img {
    max-width: 100%;
  }
  ul {
    list-style: none;
  }
  .campaigns {
    &__title {
      font-size: $font-bigger;
      letter-spacing: -1px;
      margin-bottom: $regular-gutter;
    }
    &__joinContainer {
      padding-left: $big-gutter;
      @include small-device {
        padding-left: $regular-gutter;
      }
      @include tiny-device {
        padding-left: 0;
      }
    }
    &__showcase {
      border-radius: 14px;
      width: 100%;
    }
    &__joinedCampaign {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: rgba(58, 197, 98, 0.1);
      border-radius: $radius-large;
      padding: 16px 0 12px 16px;
    }
    &__dates {
      padding: 15px $regular-gutter;
      background-color: $separator-color;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__takeCode {
      color: $toast-success-text;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 16px 20px;
      background-color: rgba(58, 197, 98, 0.1);
      border-radius: $radius-large;
      &__copy {
        font-size: 9px;
      }
      &__codeReady {
        font-size: $font-small;
        color: $toast-success-text;
        margin-bottom: 4px;
      }
    }
  }
  .tickIcon {
    color: $toast-success-text;
  }
  .copyIcon {
    cursor: pointer;
  }
  .locationIcon {
    color: $secondary-color !important;
  }
}
