@import 'index.scss';

#cardApplicationSteps {
  display: flex;
  flex-direction: row;
  position: relative;

  .cardApplicationSteps {
    width: 550px;
    margin: 0 auto;

    @include tiny-device {
      width: auto;
    }
    &__back {
      position: absolute;
      color: #fff;
      cursor: pointer;
      top: 49px;
    }
    &__bg {
      position: absolute;
      background-color: #1581be;
      height: 120px;
      z-index: -2;
      left: 0;
      right: 0;
    }
    &__stepper {
      width: auto;
      margin: 0 auto;
      border-radius: 14px;
      border: solid 2px $separator-color;
      background-color: #fff;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 20px;

      @include tiny-device {
        width: auto;
      }
    }
  }
  .tickIcon {
    color: $toast-success-text;
  }
}
