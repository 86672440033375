@import 'index.scss';

#hes {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .hes {
    &__title {
      margin-bottom: $large-gutter;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
