@import 'index.scss';

#chooseCreditCard {
  cursor: pointer;
  margin-top: $regular-gutter;
  .chooseCreditCard {
    &__container {
      border: 2px solid $light-gray-color;
      padding: 10px 10px 11px 16px;
      border-radius: $radius-large;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__toBeLoaded {
      margin-bottom: 5px;
    }
  }
  .abonmanCardIcon,
  .creditCardWithPlusIcon {
    width: 24px;
    margin-right: 16px;
  }
  .ChevronDarkRightIcon {
    transform: rotate(90deg);
  }
}
