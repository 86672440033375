@import 'index.scss';

#loginLogItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0 16px 0;
}

.loginLogItem {
  &__divider {
    border-bottom: 2px solid $separator-color;
  }
}
