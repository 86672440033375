@import 'index.scss';

#dialog-otp {
  .Mui-disabled {
    background-color: $disabled-color !important;
  }

  .dialogContainerFull {
    padding: 50px;
    display: flex;
    align-items: center;
  }

  .MuiDialog-paper {
    margin: 0;
  }
}
