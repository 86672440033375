@import 'index.scss';

#userAdressDetail {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  input {
    text-transform: capitalize;
    cursor: pointer;
    -webkit-text-fill-color: #383c52;
    -webkit-opacity: 1;
    color: #383c52;
    //background: white;
  }
  .userAdressDetail {
    &__title {
      margin-bottom: $large-gutter;
      font-weight: bold;
    }
    &--disabled {
      input,
      textarea {
        background-color: #f5f5f6;
        border-radius: 12px;
      }
    }
    &__postCode {
      .MuiInputBase-input.Mui-disabled {
        cursor: text;
      }
    }
    &__adressDetail {
      .MuiFilledInput-multiline {
        padding: 0;
      }
      textarea {
        padding: 20px 14px 7px 14px !important;
      }
    }
    &__postCode {
      input {
        cursor: inherit;
      }
    }
  }
  .chevronDown {
    position: absolute;
    right: 8px;
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent !important;
  }
}
