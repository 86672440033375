@import 'index.scss';

#adressItem {
  display: flex;
  background-color: $separator-color;
  min-height: 79px;
  padding: 14px 20px 0 16px;
  border-radius: $radius-large;

  .adressItem {
    &__edit {
      font-size: 13px;
    }
    &__title {
      font-weight: bold;
      font-size: $font-regular;
      margin-bottom: 4px;
      text-transform: capitalize;
    }
    &__adress {
      line-height: 1.43;
      letter-spacing: -0.3px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
