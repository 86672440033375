@import 'index.scss';

#faqItem {
  padding: 16px;
  border-radius: 12px;
  border: solid 2px $separator-color;
  margin-top: $small-gutter;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
