@import 'index.scss';

#digitalCardSettings {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
    width: calc(100% - 32px);
  }
  &__name {
    text-transform: capitalize;
    font-weight: 700;
    line-height: 21px;
  }
}
