@import 'index.scss';

#photo_intro {
  padding-top: 32px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
  width: 660px;
  box-sizing: border-box;
  .button {
    margin-top: 40px;
  }
  @include tiny-device {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 20px;
  }
  h5 {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.6px;
    color: $secondary-color;
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.3px;
    color: #606375;
    p {
      @include tiny-device {
        margin: 0;
      }
    }
  }
  img {
    width: 100%;
  }
  .introImagesWeb {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    @include tiny-device {
      display: none;
    }
    .errorPhoto {
      position: relative;
      width: 90px;
      height: 90px;
      border-radius: 10px;
      .error {
        position: absolute;
        bottom: -15px;
        right: -13px;
      }
      span {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #ff5959;
      }
    }
    .successPhoto {
      position: relative;
      width: 103px;
      height: 126px;
      border-radius: 10px;
      .success {
        position: absolute;
        bottom: -13px;
        right: -13px;
      }
      span {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #3ac562;
      }
    }
  }
  .introImagesMobil {
    display: none;
    justify-content: space-between;
    align-items: flex-start;
    @include tiny-device {
      display: flex;
      width: 260px;
      margin-left: calc(50% - 130px);
    }
    .errors {
      div.con {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 12px;
        column-gap: 12px;
      }
      span {
        display: flex;
        font-size: 12px;
        line-height: 18px;
        justify-content: center;
        color: #ff5959;
        margin-top: 14px;
      }
    }
    .errorPhoto {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: 10px;
      .error {
        position: absolute;
        bottom: -15px;
        right: -13px;
      }
    }
    .successPhoto {
      position: relative;
      width: 103px;
      height: 140px;
      border-radius: 10px;
      .success {
        position: absolute;
        bottom: -13px;
        right: -13px;
      }
      span {
        display: flex;
        font-size: 12px;
        line-height: 18px;
        justify-content: center;
        color: #3ac562;
        margin-top: 11px;
      }
    }
  }
}
