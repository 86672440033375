@import 'index.scss';

#campaignConditions {
  .campaignConditions {
    &__content {
      margin-top: $regular-gutter;
      ul {
        padding-left: $regular-gutter;
      }
    }
  }
}
