@import 'index.scss';

#dialogWithActions {
  .dialogContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .dialogTerms {
    &-textContainer {
      overflow: auto;
      margin-bottom: $large-gutter;

      @include tiny-device {
        margin-bottom: 2px;
      }
    }
  }
  .imageContainer {
    text-align: center;
    .showcase__img {
      width: 100%;
      padding-left: 20px;
      @include tiny-device {
        width: 257px;
        padding-left: 0px;
      }
    }
  }
}
