@import 'index.scss';

#transactionButton {
  .transactionButton {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $separator-color;
      padding-left: $regular-gutter;
      height: 62px;
      border-radius: $radius-large;
      cursor: pointer;
      @include tiny-device {
        height: $large-gutter;
        padding-left: $small-gutter;
        padding-right: 5px;
      }
      svg {
        width: 24px;
        height: 24px;
      }
      &__title {
        font-size: $font-regular;
        font-weight: bold;
        margin-left: 16px;
        text-align: left;
        @include tiny-device {
          font-size: $font-smaller;
          margin-left: $small-gutter;
        }
      }
    }
  }
}
