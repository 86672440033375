@import 'index.scss';

%button-style {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  padding: 10px 23px 12px;
  border-radius: 14px;
  font-size: 13px;
  position: relative;
  cursor: pointer;
}

#mapInterface {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50;
  width: 100%;
  height: auto;
  opacity: 1;
  z-index: 999;

  .mapInterface {
    &__nav {
      padding: 0 calc(10%) 0 calc(5%);
      @include tiny-device {
        padding: 0 calc(5%) 0 calc(5%);
      }
    }
    &__item-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: $small-gutter;
      padding: 4px $small-gutter 4px $small-gutter;
      cursor: pointer;
      margin-left: $small-gutter;
      font-size: 13px;
      margin-top: $small-gutter;
      font-weight: 600;
      color: $secondary-color;
      height: 20px;

      &__icon {
        width: $regular-gutter;
        height: $regular-gutter;
        margin-right: 6px;
      }
    }
    &__right-side {
      width: 100%;
      margin-top: $small-gutter;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__options {
      width: 180px;
      font-weight: 600;
      @include tiny-device {
        display: flex;
        justify-content: flex-end;
        &__label {
          display: none;
        }
      }

      &__location-box {
        @extend %button-style;
        background-color: $secondary-color;
        color: #fff;
      }

      &__list-box {
        @extend %button-style;
        flex-direction: row;
        background-color: #fff;
        color: $secondary-color;

        @include tiny-device {
          justify-content: center !important;
          margin-top: 0px;
          margin-left: $small-gutter;
          width: 43px;
        }

        &__icon {
          position: absolute;
          left: 16px;
          z-index: 2;
          @include tiny-device {
            position: relative;
            left: 0;
          }
        }
      }
    }
  }
}
