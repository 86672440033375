@import 'index.scss';

#myAccountHeader {
  margin-top: -$large-gutter;
  @include small-device {
    margin-top: -$big-gutter;
  }
  @include tiny-device {
    margin-top: -$regular-gutter;
  }
  svg {
    width: 71px !important;
    height: 71px !important;
    @include small-device {
      width: 60px !important;
      height: 60px !important;
    }
  }
  .container {
    margin-top: 0 !important;
  }
  .myAccountHeader {
    &__bg {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 24px 0;
      @include tiny-device {
        background-position-x: -90px;
      }
    }

    &__profile {
      background-color: #fff;
      padding: 12px;
      border-radius: $radius-large;
      display: inline-block;
      margin-right: $big-gutter;
      @include small-device {
        margin-right: $regular-gutter;
        padding: 10px 12px;
      }
      @include tiny-device {
        margin-right: $small-gutter;
        padding: 4px 5px;
      }
    }
    &__user--wrapper {
      display: flex;
      @include tiny-device {
        display: block;
      }
    }
    &__user--info {
      color: #fff;
      text-transform: capitalize;
      font-weight: bold;
      font-size: $font-largest;
      @include regular-device {
        font-size: $font-bigger;
      }
      @include tiny-device {
        font-size: $font-big;
      }
      &:first-letter {
        text-transform: capitalize;
      }
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}
