@import 'index.scss';

#card-delete {
  width: 100%;
  .card-delete {
    &__image {
      width: 100px;
      height: 154px;
      display: flex;
      margin: 0 auto;
      margin-top: $big-gutter;
      margin-bottom: $big-gutter;
    }
    &__inner {
      width: 550px;
      margin: 0 auto;
      @include tiny-device {
        width: auto;
      }
    }
  }
}
