@import 'index.scss';

#balanceTransfer {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  .balanceTransfer {
    &__title {
      font-weight: bold;
      margin-bottom: $large-gutter;
      text-align: center;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
