@import 'index.scss';

#dashboardFooter {
  padding: 52px 0;
  border-top: 1px solid $light-gray-color;
  margin-top: auto;

  .dashboardFooter__right--logo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @include tiny-device {
      justify-content: center;
      margin-top: $small-gutter;
    }
  }

  .dashboardFooter--links {
    p,
    svg {
      color: $checkbox-label-color;
    }
    &:hover {
      cursor: pointer;
      p,
      svg {
        color: $secondary-color;
      }
    }
  }
}
