@import 'index.scss';

#noCreditCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .add--card {
    border: 2px solid $light-gray-color;
    display: flex;
    align-items: center;
    border-radius: 14px;
    margin-top: $large-gutter;
    padding: 11px 48px 12px 16px;
    max-width: 212px;
    width: 100%;
    cursor: pointer;
    @include tiny-device {
      max-width: 232px;
    }
  }
}
