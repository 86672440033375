@import 'index.scss';

#allCards {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  .my__cards {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $regular-gutter;
      @include tiny-device {
        margin-bottom: $big-gutter;
      }
    }

    &-mobileTitle {
      display: none;
      @include tiny-device {
        display: block;
      }
    }
  }
}
