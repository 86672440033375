@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Mark Pro Light'), url('../fonts/MarkProLight.woff') format('woff');
}

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Mark Pro Regular'), url('../fonts/MarkPro.woff') format('woff');
}

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Mark Pro Medium'), url('../fonts/MarkProMedium.woff') format('woff');
}

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Mark Pro Bold'), url('../fonts/MarkProBold.woff') format('woff');
}

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Mark Pro Black'), url('../fonts/MarkProBlack.woff') format('woff');
}

.input-text {
  color: $input-color;
  font-weight: bold;
}
