@import 'index.scss';

#hesUnregistered {
  .hesUnregistered {
    &__description {
      margin-bottom: $big-gutter;
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
    &-inputContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: $big-gutter;
      @include tiny-device {
        flex-direction: column;
        justify-content: flex-start;
        min-height: unset;
        margin-bottom: 0;
      }

      &__input {
        width: 68%;

        @include tiny-device {
          width: 100%;
        }

        input {
          padding-top: 22px;
        }
      }

      button {
        width: 30%;

        @include tiny-device {
          margin-top: $regular-gutter;
          margin-bottom: $regular-gutter;
          width: 100%;
        }
      }
    }
  }
}
