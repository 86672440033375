@import 'index.scss';

#itemtypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 17px 0px 19px 14px;
  justify-content: space-between;
  cursor: pointer;
  .itemtypes {
    &__col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-left: 13.7px;
    }
    &__icon {
      width: 24px;
      height: 24px;
    }
    &__side {
      display: flex;
      align-items: center;
    }
  }
}
.itemtypes_cursor {
  cursor: auto !important;
}
.itemtypes__divider {
  width: 100%;
  height: 2px;
  background-color: #f6f5f6;
}
