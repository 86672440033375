@import 'index.scss';
#addCardSuccessLoadBalance {
  .add-card-success-load-balance {
    &__container {
      max-width: 280px;
      margin: auto;
      text-align: center;
      margin-top: $big-gutter;
    }
    &__desc {
      max-width: 750px;
      margin: auto;
      letter-spacing: -0.4px;
      margin-top: $big-gutter;
      @include tiny-device {
        margin-top: $regular-gutter;
      }
    }
    &__img--wrapper {
      position: relative;
      max-width: 100px;
      margin: auto;
      margin-top: 60px;
      @include tiny-device {
        margin-top: $large-gutter;
      }
      img {
        width: 100px;
      }
      &__red {
        filter: drop-shadow(2px 2px 5px $red-card-shadow-color);
      }
      &__green {
        filter: drop-shadow(2px 2px 5px $green-card-shadow-color);
      }
      &__orange {
        filter: drop-shadow(2px 2px 5px $orange-card-shadow-color);
      }
      &__darkblue {
        filter: drop-shadow(2px 2px 5px $darkblue-card-shadow-color);
      }
      &__blue {
        filter: drop-shadow(2px 2px 5px $blue-card-shadow-color);
      }
    }
  }
  .greenTickBigFilledIcon {
    position: absolute;
    right: -30px;
    top: -30px;
  }
  .MuiButton-root {
    font-weight: bold !important;
    cursor: pointer;
  }
}
