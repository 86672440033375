@import 'index.scss';

#cardTransactionHistory {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .lastTransactionItem__desc {
    margin-left: 60px;
  }
}
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #fff !important;
  background-color: $alternative-color-4 !important;
}
