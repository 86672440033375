@import 'index.scss';

#mobileMasterpassConnect {
  padding: 0 16px;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
