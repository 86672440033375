@import 'index.scss';

#mobileAccountOtp {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;

  .mobileAccountOtp {
    padding: 60px 50px 50px 50px;
    width: 460px;

    @include tiny-device {
      width: auto;
    }
  }

  @include tiny-device {
    width: auto;
  }
}
