@import 'index.scss';

#addNewAdress {
  border: 2px solid $light-gray-color;
  display: flex;
  align-items: center;
  border-radius: 14px;
  padding: 32px 48px 33px 16px;
  cursor: pointer;
  justify-content: space-between;
}
