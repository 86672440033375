@import 'index.scss';

#agreementDetail {
  width: 940px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
    margin-bottom: 80px;
  }
  @include regular-device {
    width: auto;
  }
}
