@import 'index.scss';

#loadBalance {
  max-width: 550px;
  margin: 0 auto;
  .loadBalance {
    &__balanceItem {
      padding: 16px;
      border: 2px solid $light-gray-color;
      text-align: center;
      border-radius: $radius-large;
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;
      color: $secondary-color;
    }
    &__tempCreditCard {
      color: $toast-success-text;
      font-size: 13px;
      margin-top: $small-gutter;
    }
    &__tempCard {
      color: $toast-success-text;
      font-size: 13px;
      margin-top: $small-gutter;
    }
    &__balanceItemWrapper {
      padding: 6px 6px 6px 6px;
    }
    &__balanceItemActive {
      background-color: $alternative-color-4;
      color: #fff;
      border: 2px solid $alternative-color-4;
    }
    &__totalBalance {
      font-size: 11px;
      margin-bottom: $small-gutter;
      margin-top: $big-gutter;
      text-align: center;
    }
    &__invisibleText {
      color: #fff;
    }
    &__comisson {
      height: 21px;
    }
    &__payment {
      height: 28px;
    }
  }
}
