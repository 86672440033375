@import 'index.scss';

#waitingInstructions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px 12px 16px;
  margin-top: $regular-gutter;
  background-color: $separator-color;
  border-radius: $radius-large;
  cursor: pointer;
  @include tiny-device {
    margin-top: 15px;
  }
  .dialogContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .amountLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
