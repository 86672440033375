@import 'index.scss';

#signup {
  ul {
    padding-left: 17px;
  }
  .tickIcon {
    color: $toast-success-text;
  }

  .backIcon {
    margin-left: 20px;
    margin-top: 20px;

    @include tiny-device {
      margin-left: 0;
      margin-top: 0;
    }
  }
  .checkbox-error {
    .Mui-error {
      padding: 0 20px;
      @include tiny-device {
        padding: 0 15px;
      }
    }
  }
}
