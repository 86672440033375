@import 'index.scss';

.namesDialog {
  &__btn {
    height: 52px;
    border: solid 2px $light-gray-color;
  }
  &__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .dialogContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  &__item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__divider {
    height: 2px;
    background-color: $separator-color;
  }
  .dialogTerms {
    &-textContainer {
      overflow: auto;
      margin-bottom: $large-gutter;

      @include tiny-device {
        margin-bottom: 2px;
      }
    }
  }
}
