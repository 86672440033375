@import 'index.scss';

#selectedCreditCard {
  margin-bottom: $small-gutter;

  cursor: pointer;
  .selectedCreditCard {
    &__container {
      padding: 16px;
      border-radius: $radius-large;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $separator-color;
      border: 2px solid $separator-color;
      position: relative;
    }
    &--img {
      max-width: 66px;
      margin-top: -30px;
      margin-right: 24px;
    }
    &--addedTempCard {
      border: 2px solid $toast-success-text !important;
    }
    &--title {
      color: $secondary-color;
      font-size: $font-small;
      margin-bottom: 4px;
    }

    &--cardNumber {
      color: $checkbox-label-color;
      font-size: $font-small;
      opacity: 0.5;
    }
    &--brand {
      max-width: 28px;
    }
    &--cardLogo {
      max-width: 66px;
    }
    &--dot {
      width: 5px;
      height: 5px;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 50%;
      opacity: 0.5;
      background-color: $placeholder-color;
    }
  }

  .ChevronDarkRightIcon {
    transform: rotate(90deg);
  }
}
