@import 'index.scss';

#card-settings {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .card-settings {
    &__pointer {
      cursor: pointer;
    }
  }
}
