@import 'index.scss';

.warn-and-tips-item {
  padding: 0 12px 0 18px;
  height: 84px;
  background-color: $separator-color;
  border-radius: 12px;
  margin-bottom: $small-gutter;
  letter-spacing: -0.5px;
  border: 1px solid $separator-color;
  cursor: pointer;
  display: flex;
  align-items: center;

  @include tiny-device {
    margin-right: 0;
    margin-bottom: 0;
  }

  &:hover {
    border-color: $primary-color;
    @include regular-device {
      border-color: $separator-color;
    }
  }
  &--title {
    @include tiny-device {
      font-size: $font-regular !important;
    }
  }
  &--desc {
    @include tiny-device {
      font-size: 13px !important;
    }
  }
}
