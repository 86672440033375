@import 'index.scss';

#digitalCardPage {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  .header {
    background: linear-gradient(
      340deg,
      $card-detail-header-linear-digital-1 1%,
      $card-detail-header-linear-digital-2 98%
    );
    position: relative;
    &__heart-web {
      max-height: 92%;
      position: absolute;
      bottom: 0;
      right: 0;
      @include tiny-device {
        display: none;
      }
    }
    &__heart-mobile {
      display: none;
      @include tiny-device {
        display: block;
        position: absolute;
        bottom: 12px;
        right: 0;
        max-height: 75%;
      }
    }
    &__copy-button {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      margin-left: 12px;
      position: relative;
      cursor: pointer;
      @include tiny-device {
        margin-left: 5px;
        margin-bottom: 0;
      }
      &__text {
        @include tiny-device {
          display: none;
        }
      }
    }
    &__settings-button {
      cursor: pointer;
    }
    &__title {
      font-weight: bold;
      font-size: 22px;
      color: #fff;
      margin-right: $regular-gutter;
      @include tiny-device {
        font-size: $font-regular-big;
        text-align: center;
        width: 100%;
      }
    }
    &__title-container {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
    }
    &__back-button {
      position: absolute;
      color: #fff;
      left: -65px !important;
      cursor: pointer;
      top: 25px;
      @include regular-device {
        left: 25px !important;
        top: 15px;
      }
      @include tiny-device {
        left: 10px !important;
        top: 20px;
      }
    }
    &__container {
      max-width: 960px;
      padding-left: $big-gutter;
      margin-left: auto;
      margin-right: auto;
      padding-top: $big-gutter;
      padding-bottom: $big-gutter;
      position: relative;
      width: auto;
      @include regular-device {
        padding-right: $regular-gutter;
        padding-top: $large-gutter;
        padding-bottom: $large-gutter;
      }
      @include tiny-device {
        padding: $regular-gutter $small-gutter 0 $regular-gutter;
        min-height: 223px;
      }
    }
  }
  .content-container {
    max-width: 1130px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include regular-device {
      width: calc(100% - 60px);
      padding: 0 $big-gutter;
    }
    @include tiny-device {
      padding: 0;
      width: unset;
      max-width: calc(100% - 32px);
    }
    &__cards {
      padding-left: 100px;
      margin-top: $big-gutter;
      @include regular-device {
        padding: 0 $big-gutter;
      }
      @include tiny-device {
        margin-top: 24px;
        padding: 0;
      }
    }
  }
  .content {
    max-width: 960px;
    padding-left: $big-gutter;
    margin-left: auto;
    margin-right: auto;
    padding-top: $big-gutter;
    padding-bottom: $big-gutter;
    width: auto;
    @include regular-device {
      padding-right: $regular-gutter;
      padding-top: $large-gutter;
      padding-bottom: $large-gutter;
    }
    @include tiny-device {
      margin: 0;
      padding: 0;
      padding-top: 16px;
      min-height: 223px;
    }
    &__process-button-container {
      display: flex;
      height: unset;
      flex-direction: row;
      gap: 8px;
      @include tiny-device {
        flex-direction: column;
        flex-wrap: wrap;
        height: 170px;
        align-content: flex-start;
      }
      @media only screen and (max-width: 370px) {
        height: unset;
      }
    }
    &__process-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 182px;
      height: 117px;

      background-color: $separator-color;
      border-radius: $radius-large;
      cursor: pointer;
      &__text {
        margin-top: 14px;
      }
      @include tiny-device {
        justify-content: flex-start;
        width: 130px;
        height: 56px;
        padding: 8px 16px;
        margin-bottom: 8px;
        flex-direction: row;
        &__text {
          margin-top: 0;
          margin-left: 12px;
        }
      }
    }
    &__icon {
      color: #1baadc;
      @include tiny-device {
        color: $card-detail-header-linear-digital-2;
        width: 24px;
        height: 24px;
      }
    }
    &__action-title {
      margin-top: 50px;
      margin-bottom: 24px;

      @include tiny-device {
        margin-top: 24px;
        margin-bottom: 16px;
      }
    }
  }
}
