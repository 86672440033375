@import 'index.scss';

#microCredit {
  width: 656px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .microCredit {
    &__item {
      padding: 12px 10px 12px 24px;
      border-radius: 12px;
      background-color: $separator-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;
      &__img {
        width: 48px;
      }
      &__first-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-transform: capitalize;
      }
    }
  }
}
