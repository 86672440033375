@import 'index.scss';

#dialogCity .MuiDialog-paperScrollPaper {
  height: auto !important;
}

#dialogCity .dialogCity-container {
  min-height: unset !important;
}

.typesDialog {
  &__btn {
    height: 52px;
    border: solid 1px #ffabab !important;
    background-color: #ffabab !important;
    &__error {
      font-size: 10px;
      color: ffabab;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .dialogContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  &__item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__divider {
    height: 2px;
    background-color: $separator-color;
  }
  .dialogTerms {
    &-textContainer {
      overflow: auto;
      margin-bottom: $large-gutter;

      @include tiny-device {
        margin-bottom: 2px;
      }
    }
  }
}
