@import 'index.scss';

#cardApplicationsItem {
  .cardApplicationsItem {
    border-radius: 12px;
    background-color: $separator-color;
    display: flex;
    flex-direction: row;
    padding: 12px 10px 12px 24px;
    align-items: center;
    cursor: pointer;
    &__img {
      width: 31px;
      height: 48px;
    }
    &__column {
      display: flex;
      flex-direction: column;
      padding-left: $regular-gutter;
      justify-content: left;
      width: 100%;
    }
  }
}
