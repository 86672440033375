@import 'index.scss';

.digital-account-info-box {
  &__title {
    color: rgba(255, 255, 255, 0.45);
    font-size: $font-regular;
    font-weight: 700;
    @include tiny-device {
      color: rgba(255, 255, 255, 0.7);
      font-size: $font-smaller;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
  &__value {
    font-size: 22px;
    &__iban {
      @include tiny-device {
        font-size: 13px;
      }
    }
  }
  &__marginLarge {
    margin-bottom: 10px;
    @include tiny-device {
      margin-bottom: 4px;
    }
  }
  &__marginSmall {
    margin-bottom: 5px;
    @include tiny-device {
      margin-bottom: 4px;
    }
  }
  &__tl-text {
    font-size: 11px;
  }
}
