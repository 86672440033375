@import 'index.scss';

#warn-and-tips {
  margin: $large-gutter auto 0 auto;
  @include regular-device {
    margin: $regular-gutter auto 0 auto;
  }
  @include tiny-device {
    margin: $big-gutter auto 0 auto;
  }
}
