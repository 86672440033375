@import 'index.scss';

#noAdress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .locationIcon {
    color: $disabled-color;
    width: 85px;
    height: 85px;
    margin-bottom: $regular-gutter;
  }
  .noAdress {
    &__addAdress {
      border: 2px solid $light-gray-color;
      display: flex;
      align-items: center;
      border-radius: 14px;
      padding: 11px 48px 12px 16px;
      max-width: 212px;
      width: 100%;
      cursor: pointer;
      justify-content: space-between;
    }
  }
}
