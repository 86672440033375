@import 'index.scss';

#cardApplicationRejectImage {
  width: 550px;
  margin: 0 auto;

  @include tiny-device {
    width: auto;
  }

  .cardApplicationRejectImage {
    &__img-area {
      margin-top: $regular-gutter;
      padding: 15px 24px 14px 16px;
      border-radius: 14px;
      border: solid 2px $separator-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__change-btn {
      cursor: pointer;
    }
  }
  .Mui-disabled {
    background-color: $disabled-color !important;
  }
}
