@import 'index.scss';

#changePhoto {
  width: 280px;
  margin: 0 auto;
  .changePhoto {
    &__area {
      width: 200px;
      height: 256px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: $separator-color;
      margin-top: $big-gutter;
      flex-direction: column;
      cursor: pointer;
      position: relative;
    }

    &__img {
      width: 200px;
      height: 256px;
      border-radius: 14px;
    }

    &__change-image {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 136px;
      height: 28px;
      padding: 4px 13px 6px 15px;
      border-radius: 8px;
      -webkit-backdrop-filter: blur(18px);
      backdrop-filter: blur(18px);
      font-size: 11px;
      color: #fff;
      bottom: 20px;
      z-index: 999;
    }
  }
}
