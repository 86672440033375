@import 'index.scss';

#cardApplicationInformation {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .cardApplicationInformation {
    &__area {
      width: 100%;
      border-radius: 12px;
      padding: 24px 16px;

      &__row {
        display: flex;
        justify-content: space-between;

        &__ship {
          cursor: pointer;
        }
      }
    }
  }
}
.tickIcon {
  color: $toast-success-text;
}
