@import 'index.scss';

#organisationStep {
  input {
    text-transform: capitalize;
    cursor: pointer;
  }
  svg {
    position: absolute;
    right: 8px;
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: transparent !important;
  }
  .organisationStep {
  }
}
.noPointerEvents input {
  pointer-events: none;
}
.t-checkbox {
  cursor: pointer;
  background-color: #f5f5f6;
  padding: 14px 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &--selected {
    .t-checkbox__icon {
      background-image: url('/Assets/Icons/check-filled.svg');
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    background-image: url('/Assets/Icons/check.svg');
    margin-right: 16px;
  }
  &__content {}
  &__title {
    color: #383c52;
    font-weight: bold;
  }
  &__desc {
    margin-top: 4px;
    color: #606375;
    font-size: 14px;
  }
}
