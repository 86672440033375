@import 'index.scss';

#clarification {
  .clarification {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #515659;
    &__page {
      margin-top: $regular-gutter;
      margin-bottom: $regular-gutter;
      background-color: #515659;
      @include tiny-device {
        max-width: 100%;
      }
    }
  }
}
