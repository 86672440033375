@import 'index.scss';

#modal3DSecure {
  font-size: 12px;
}

#frame {
  width: 100%;
  height: 400px;
  border: 0px;
  z-index: 2px;

  @include tiny-device {
    width: 400px;
    height: 750px;
    margin-top: 20px;
    -ms-zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    padding: 0;
    overflow: hidden;
  }
}
