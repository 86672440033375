@import 'index.scss';

#maintenance {
  width: 100%;
  display: flex;
  justify-content: center;

  .maintenance-container {
    width: 680px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 32px 0 32px;
    @include tiny-device {
      width: auto;
    }

    &__header {
      @include tiny-device {
        font-size: $font-bigger;
      }
    }
  }
}
