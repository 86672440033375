@import 'index.scss';
#credit-card-detail {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .credit-card-detail {
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 15px 16px 14px 16px;
      border-radius: 14px;
      border: solid 2px $separator-color;
    }
    &__action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px 16px 20px;
      border-radius: 12px;
      background-color: $separator-color;
      margin-top: $small-gutter;
      font-size: $font-regular;
      font-weight: 600;
      cursor: pointer;
    }
    &__check {
      width: 22px;
      height: 22px;
      padding: 0 !important;
    }
    &__masterpassLogo {
      margin-top: 100px;
      @include tiny-device {
        margin-top: $large-gutter;
      }
    }
  }
}
