@import 'index.scss';

.transactionListHeader {
  background-color: $light-background-color;
  padding: 16px 16px 16px 15px;
  border-radius: 14px;
  margin-top: $regular-gutter !important;
  &__button {
    background-color: $light-background-color;
    width: auto;
    font-size: 11px;
    color: $checkbox-label-color;
    line-height: 1.09;
    border: solid 2px $light-gray-color;

    &__badge {
      position: absolute;
      background-color: red;
      width: 16px;
      height: 16px;
      font-size: 10px;
      border-radius: 14px;
      top: -5px;
      right: -5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include tiny-device {
      height: 40px;
      margin-top: $small-gutter !important;
    }
  }
}
