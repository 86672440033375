@import 'index.scss';

#userAddressPage {
  padding: 0 $big-gutter;

  @include tiny-device {
    padding: 0 $small-gutter;
  }
  .userAddressPage {
    &__title {
      margin-bottom: 66px;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
