@import 'index.scss';

#userAddCard {
  .userAddCard {
    &__title {
      margin-top: $small-gutter;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #formError {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}
