@import 'index.scss';

#user-card-info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 14px 16px;
  border-radius: $radius-large;
  border: solid 2px $separator-color;
  margin-top: $small-gutter;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
