@import 'index.scss';

#welcomeLinks {
  text-align: right;
  width: calc(100% - 155px);

  &.welcomeLinks {
    &--isDrawer {
      width: calc(100% - 20px);
      margin: auto 0 110px 0;

      @include tiny-device {
        margin: auto 0 20px 0;
      }
    }

    &--isMobile {
      @include regular-device {
        display: none;
      }
    }

    &--smallHeightDevice {
      margin: auto 0 70px 0;
    }
  }

  img {
    &:first-child {
      margin-right: $small-gutter;
    }
  }
}
