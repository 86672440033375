@import 'index.scss';

.cardApplicationItem {
  &__item {
    padding: 12px 10px 12px 24px;
    border-radius: 12px;
    background-color: $separator-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;

    &__img {
      width: 48px;
    }

    &__first-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: capitalize;
    }

    h3 {
      text-align: left;
    }

    >svg {
      flex-shrink: 0;
    }
  }

  @media (max-width: 768px) {
    .cardApplicationItem__item {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

}