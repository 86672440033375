@import 'index.scss';

#delete-card-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 280px;
  .delete-card-success-container {
    &__secondary_button {
      background-color: #fff;
      border: solid 2px $light-gray-color;
    }
  }
}
