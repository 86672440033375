@import 'index.scss';

#paymentOrder {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  .arrowLeftIcon {
    position: absolute;
    left: 16px;
    top: 2px;
    bottom: 0;
    margin: auto;
  }
  .paymentOrder {
    &__header {
      width: 100%;
      position: relative;
      padding: 20px 16px;
      box-sizing: border-box;
      h3 {
        div {
          margin: 0;
        }
      }
    }
    &__content {
      .content {
        &__row {
          height: 54px;
          display: flex;
          align-items: center;
          padding: 0px 16px;
          border-top: solid 2px #f5f5f6;
          &__left {
            width: 50%;
          }
          &__title {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: -0.3px;
            padding: 0px;
            margin: 0;
            color: #888a97;
          }
          &__value {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.3px;
            padding: 0px;
            margin: 0;
            color: $secondary-color;
            &__desc {
              font-size: 12px;
              font-weight: 300;
              letter-spacing: -0.3px;
              padding: 0px;
              margin: 0;
              color: $secondary-color;
            }
          }
          &__right {
            width: 50%;
          }
          &__info__text {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.3px;
            color: #888a97;
          }
        }
      }
      .borderBottom {
        border-bottom: solid 2px #f5f5f6;
        padding-top: 5px;
        padding-bottom: 5px;
        height: auto;
      }
    }
  }
}
.paymentOrderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  .button__container {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    button:nth-child(1) {
      margin-right: 16px;
      background-color: #fff;
      color: $secondary-color;
      border: solid 2px #f5f5f6;
    }
    button:nth-child(2) {
      margin-right: 0px;
    }
    .lighttheme {
    }
  }
}
