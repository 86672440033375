@import 'index.scss';

#pointList {
  width: 100%;
  min-height: 30vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: calc(10%);
  padding-bottom: 10px;
  background-color: $separator-color;

  @include regular-device {
    padding-top: calc(15%);
  }

  @include tiny-device {
    padding-top: calc(58%);
  }

  .pointList {
    &__container {
      padding-left: calc(5%) !important;
      padding-right: calc(5%) !important;
    }

    &__item-container {
      padding: 16px 16px 20px 16px;
      border-radius: 14px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
      align-items: center;

      &__btn {
        display: flex;
        align-items: center;
        padding: 4px 9px 4px 9px;
        border-radius: 14px;
        border: solid 2px $light-gray-color;
        font-size: 13px;
        cursor: pointer;
      }
      &__btn:hover {
        background-color: $light-gray-color;
      }
    }
    &__pagination {
      margin-top: $regular-gutter !important;
    }
  }
}
