@import 'index.scss';

#updateAgreements {
  width: 560px;
  margin: 0 auto;

  @include tiny-device {
    width: auto;
  }
  .updateAgreements {
    &__alert-box {
      padding: 21px 23px 24px 20px;
      border-radius: 12px;
      background-color: #f5f5f6;
      display: flex;

      margin-top: $big-gutter;
    }

    &__check-container {
      padding: 16px;
      border-radius: 12px;
      border: solid 2px #f5f5f6;
    }
  }
  .disabled {
    background-color: #cad3da;
  }
}
