@import 'index.scss';

#digitalCard {
  .digitalCard-container {
    border-radius: $radius-large;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $digital-card-item-color;
    &--image {
      img {
        max-width: 80px;
        -webkit-filter: drop-shadow(2px 2px 4px $digital-card-shadow-color);
        filter: drop-shadow(2px 2px 4px $digital-card-shadow-color);
      }
    }
  }
}
