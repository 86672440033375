@import 'index.scss';

#paymentStep {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .paymentStep {
    &__desc {
      margin-bottom: 60px;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
    &__inputs {
      margin-bottom: $regular-gutter;

      @include tiny-device {
        margin-bottom: $small-gutter;
      }
    }
    &__innerContainer {
      justify-content: space-between;
    }
    &__checkbox {
      margin-bottom: $big-gutter;
      margin-top: $big-gutter;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include tiny-device {
        margin-bottom: $regular-gutter;
        margin-top: $regular-gutter;
      }
      &--flex {
        align-self: flex-start;
      }
    }
    &__cvc {
      margin-left: $regular-gutter;
      position: relative;
      @include tiny-device {
        margin-left: 0;
      }
    }
    &__expireDate {
      @include tiny-device {
        margin-bottom: $small-gutter;
      }
    }
    &__tooltip__box {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }
}
