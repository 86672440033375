@import 'index.scss';

#signupConditions {
  margin-top: -$large-gutter;
  .signupConditions {
    &-container {
      @include card-shadow;
      background-color: white;
      width: 1130px;
      padding: 50px 95px 50px;
      border-radius: $radius-regular;
      margin-bottom: 100px;

      @include regular-device {
        width: 710px;
        padding: 30px 20px;
      }

      @include tiny-device {
        width: auto;
        padding: 30px 20px;
      }
    }

    &-title {
      margin-bottom: $large-gutter;
      font-weight: 600;
      margin-top: $large-gutter;
      @include tiny-device {
        margin-bottom: $big-gutter;
      }
    }

    &-dialog {
      position: fixed;
      padding-bottom: 50px;
      padding-top: 60px;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      margin: auto;

      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 65%);

      &__inner {
        width: 235px;
        padding: 12px 8px 12px 16px;
        border-radius: 14px;
        background-color: $dark-primary-color;
        cursor: pointer;
      }
    }

    &-button-container {
      @include card-shadow;
      background-color: white;
      width: 520px;
      padding: 30px;
      margin: auto;
      margin-top: $large-gutter;
      border-radius: 23px;

      @include tiny-device {
        width: auto;
      }
    }
  }
}
