@import 'index.scss';

.cardApplicationItem {
  &__item {
    margin: 16px 0 13px 1px;
    padding: 25px 40px 24px 24px;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    background-color: $separator-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
    max-width: calc(100% - 5px);
    &__img {
      width: 48px;
    }

    &__first-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: capitalize;
    }
  }

  @media (max-width: 768px) {
    &__item {
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
