@import 'index.scss';

#contactPermissionDisableSuccess {
  width: 550px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  @include tiny-device {
    width: auto;
  }
}
#contactPermissionSuccess {
  width: 550px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include tiny-device {
    width: auto;
  }
}
