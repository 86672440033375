@import 'index.scss';

#credit-card-list {
  .credit-card-list {
    &__item {
      margin-bottom: $small-gutter;
      background-color: $separator-color;
      border-radius: $font-small;
      padding: 16px 16px 17px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    &__btn {
      margin-bottom: $small-gutter;
      background-color: $separator-color;
      border-radius: $font-small;
      padding: 16px 16px 17px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__title {
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
.rootAbsolute {
  margin-top: $big-gutter !important;
  @include tiny-device {
    margin-top: $small-gutter !important;
  }
  & .MuiButton-startIcon {
    position: absolute;
    left: 16px;
  }
}
