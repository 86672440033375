@import 'index.scss';

#hesRegistered {
  @include card-shadow;
  padding: $big-gutter;
  border-radius: 23px;
  @include tiny-device {
    padding: $big-gutter $small-gutter;
  }
}
