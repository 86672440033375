@import 'index.scss';

#card-delete-item {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 17px;
  margin-top: $small-gutter;
  border-radius: 14px;
  border: solid 2px $light-gray-color;
  cursor: pointer;
}
