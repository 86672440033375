@import 'index.scss';

#change-password {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  ul {
    padding-left: 17px;
  }
  .changePassword {
    &__title {
      margin-bottom: $bigger-gutter;
      text-align: center;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
