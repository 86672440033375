@import 'index.scss';

#mapPopup {
  width: 370px;
  padding: 16px 10px 22px 16px;
  border-radius: 14px;
  background-color: #fff;

  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  margin-bottom: $regular-gutter;
  left: calc(5%);

  @include tiny-device {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    border-radius: 0px;
  }

  .mapPopup {
    display: flex;
    flex-direction: row;

    &__first-column {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    &__second-column {
      display: flex;
      flex: 0.3;
      align-items: center;
      justify-content: center;

      &__link {
        cursor: pointer;
      }
    }
  }
}
