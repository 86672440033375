@import 'index.scss';

.container {
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /*  max-width: 1440px;
  padding: 0 155px; */
  @include regular-device {
    width: calc(100% - 60px);
    padding: 0 $big-gutter;
  }
  @include tiny-device {
    padding: 0 $small-gutter;
    width: calc(100% - 20px);
  }
}
