@import 'index.scss';

#virtualCardItem {
  margin-top: $big-gutter;
  position: relative;
  @include tiny-device {
    margin-top: $small-gutter;
  }
  .card-item {
    &__container {
      border-radius: $radius-large;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-content: center;
      img {
        max-width: 66px;
        margin-top: -35px;
        max-height: 102px;
      }
    }
    &__virtual {
      background: linear-gradient(311.54deg, rgba(128, 128, 128, 0.74) 0%, rgba(77, 77, 77, 0.74) 100%);
      &__text {
        color: #fff;
      }
    }
    &__plus {
      background: $plus-card-item-color;
    }
    &__not--active {
      opacity: 0.5;
    }
  }
}
