@import 'index.scss';

#copyTooltip {
  text-align: center;
  font-size: $font-regular;
  line-height: $font-regular-big;
  letter-spacing: -0.3px;
  color: #fff;

  background: $checkbox-label-color;
  padding: 12px 24px;
  display: block;
  border-radius: $radius-large;

  position: absolute;
  z-index: 9999;
  left: -12px;
  bottom: 34px;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $checkbox-label-color;

    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include tiny-device {
    left: initial;
    bottom: initial;
    top: 34px;
    right: -20px;

    &::after {
      top: -6px;
      left: initial;
      right: 20px;

      border-top: initial;
      border-bottom: 6px solid $checkbox-label-color;
    }
  }
}
