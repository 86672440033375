@import 'index.scss';

#dashboardHeader {
  /*  margin-bottom: $large-gutter;

  @include regular-device {
    margin-bottom: $big-gutter;
  } */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  #welcomeDrawer {
    display: none;
    @include tiny-device {
      display: block;
      position: absolute;
      right: 10px;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    align-items: center;

    @include tiny-device {
      display: none;
    }

    li {
      margin-right: $large-gutter;
      font-size: $font-regular-big;
      font-weight: bold;
      letter-spacing: -0.5px;
      @include tiny-device {
        margin-right: $big-gutter;
      }
    }
  }

  .dashboardHeader {
    &__logo {
      &--wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        @include tiny-device {
          justify-content: center;
        }
      }
      &--item {
        cursor: pointer;
        @include tiny-device {
          width: 125px;
        }
      }
    }
    &--blue {
      background-color: $alternative-color-3;
      padding: 35px 0 32px 0;
      @include tiny-device {
        padding: $regular-gutter 0;
      }
      ul {
        li {
          a:hover {
            color: #fff;
          }
        }
      }
    }
    &--white {
      background-color: '#fff';
      padding: 35px 0 32px 0;
      border-bottom: 1px solid $light-gray-color;
      @include tiny-device {
        padding: $regular-gutter 0;
      }
      &--activeLink {
        color: $dark-primary-color !important;
      }
      ul {
        li {
          .MuiTypography-colorPrimary {
            color: $secondary-color;
          }
          a:hover {
            color: $dark-primary-color;
          }
        }
      }
    }
  }
}
