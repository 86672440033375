@import 'index.scss';

#headerWelcome {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 155px;
  padding-right: 155px;

  @include regular-device {
    padding-left: $big-gutter;
    padding-right: $big-gutter;
  }

  @include tiny-device {
    padding-left: $regular-gutter;
    padding-right: $regular-gutter;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    @include regular-device {
      display: none;
    }

    li {
      padding: 10px 50px 10px 50px;
      border-right: solid 1px $dark-separator-color;
      a {
        color: $secondary-color;
        font-weight: bold;
      }

      &:first-child {
        padding-left: 0;
        a {
          color: $checkbox-label-color;
          font-weight: normal;
        }
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
        span {
          display: flex;
          img {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.welcomeHeaderActiveItem {
  color: $dark-primary-color !important;
}
