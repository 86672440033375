@import 'index.scss';

#contactWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 550px;
  align-self: center;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }
  .contactWrapper {
    &__title {
      margin-bottom: $large-gutter;
      text-align: center;
      font-weight: bold;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
  }
}
