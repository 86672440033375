@import 'index.scss';
#transactionDetail {
  .transactionDetail {
    &__itemTitle {
      font-size: 11px;
      margin-bottom: 4px;
    }
  }
  hr {
    color: $separator-color !important;
  }
}
