@import 'index.scss';

//Burda class isimler & ile çalışmadı
//Sebebini bilmiyorum
//FIXME: classname yemedi
// cunku gardaşım burası dialog en dış kısımda açıyor . root da css vermen egerekli :)

.adressListDialog__btn {
  margin-top: $small-gutter !important;
}
.adressListDialog__item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 10px 14px 16px;
  border-radius: 12px;
  border: solid 2px $secondary-color;
  background-color: $separator-color;
  margin-top: $small-gutter;
  cursor: pointer;
}
