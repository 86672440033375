@import 'index.scss';

#selectedCard {
  margin-bottom: $small-gutter;
  margin-top: $big-gutter;
  cursor: pointer;
  .selectedCard {
    &__container {
      padding: 10px 12px 11px 12px;
      border-radius: $radius-large;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    &--img {
      max-width: 66px;
      margin-top: -30px;
      max-height: 102px;
    }
    &--title {
      color: $secondary-color;
      font-size: $font-small;
      margin-bottom: 3px;
    }
    &--aliasNumber {
      color: $checkbox-label-color;
      font-size: $font-small;
    }
    &--addedTempCard {
      border: 2px solid $toast-success-text !important;
    }
    &__blue {
      background-color: $blue-card-item-color;
      border: 2px solid $blue-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $blue-card-shadow-color);
        }
      }
    }
    &__red {
      background-color: $red-card-item-color;
      border: 2px solid $red-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $red-card-shadow-color);
        }
      }
    }
    &__green {
      background-color: $green-card-item-color;
      border: 2px solid $green-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $green-card-shadow-color);
        }
      }
    }
    &__orange {
      background-color: $orange-card-item-color;
      border: 2px solid $orange-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $green-card-shadow-color);
        }
      }
    }
    &__darkblue {
      background-color: $darkblue-card-item-color;
      border: 2px solid $darkblue-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $green-card-shadow-color);
        }
      }
    }
    &__city {
      background-color: $city-card-item-color;
      border: 2px solid $city-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $city-card-shadow-color);
        }
      }
    }
    &__digital {
      background-color: $digital-card-item-color;
      border: 2px solid $digital-card-item-color;
      background-clip: padding-box;
      &--shadow {
        img {
          filter: drop-shadow(2px 2px 5px $digital-card-shadow-color);
        }
      }
    }
  }

  .ChevronDarkRightIcon {
    transform: rotate(90deg);
    margin-bottom: -10px;
  }
}
