@import 'index.scss';

#lastTransactionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $separator-color;
  padding: 13px 12px 13px 12px;
  border-radius: 14px;
  margin-bottom: $small-gutter;
  cursor: pointer;
  @include tiny-device {
    padding-left: 20px;
  }
  .lastTransactionItem {
    &__desc {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 32px !important;
      margin-right: 16px !important;
      max-width: 300px;
      overflow: hidden;
      overflow-wrap: break-word;
      @include tiny-device {
        margin-left: 21px !important;
        margin-right: 11px !important;
        overflow-wrap: anywhere;
      }
    }
    &__date {
      min-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include tiny-device {
        min-width: 50px;
      }
    }
    &__price {
      display: flex;
      justify-content: flex-end;
      min-width: 80px !important;
      text-align: center;

      @include tiny-device {
        min-width: 80px !important;
      }
    }
  }
}
