@import 'index.scss';
%locationIcons {
  position: absolute;
  transform: translate(-50%, -100%);
}

#map {
  min-height: 30vh;
  z-index: 99999999999;
  .map {
    &__location-icon {
      @extend %locationIcons;
    }
    &__current-icon {
      @extend %locationIcons;
      width: 30px;
      height: 30px;
      z-index: 99999;
    }
    &__currentLocation {
      width: 40px;
      height: 40px;
      border-radius: 35px;
      background-color: #fff;
      position: absolute;
      right: 10px;
      bottom: 175px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &__icon {
        margin-right: 2px;
        margin-top: 4px;
      }
    }
  }
}
