@import 'index.scss';

#mobileAddCreditCard {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px 16px;
  position: relative;
  .arrowLeftIcon {
    top: 0;
    margin-left: 10px;
  }
  .mobileAddCreditCard {
    &__desc {
      margin-bottom: 60px;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
    &__inputs {
      margin-bottom: $regular-gutter;

      @include tiny-device {
        margin-bottom: $small-gutter;
      }
    }
    &__innerContainer {
      justify-content: space-between;
    }
    &__bottom {
      width: 100%;
    }
    &__checkbox {
      margin-bottom: $big-gutter;
      margin-top: $big-gutter;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include tiny-device {
        margin-bottom: $regular-gutter;
        margin-top: $regular-gutter;
      }
      &--flex {
        align-self: flex-start;
      }
    }
    &__middleBlock {
      @include tiny-device {
        margin-bottom: 0 !important;
      }
    }
    &__cvc {
      margin-left: $small-gutter;
      position: relative;
      @include tiny-device {
        margin-left: 0;
      }
    }

    &__tooltip__box {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }
  label {
    font-weight: 700;
    color: $placeholder-color !important;
  }
  Button:disabled {
    opacity: 0.5;
  }
}
.mobileAddCreditCardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  #formError {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin: 0 16px;
  }
}
