@import 'index.scss';

.semi-container {
  max-width: 550px;
  margin: 0 auto;
  @include regular-device {
    width: calc(100% - 40px);
    padding: 0 $big-gutter;
  }
  @include tiny-device {
    padding: 0 $small-gutter;
    width: calc(100% - 20px);
  }
}
