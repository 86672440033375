$gray-color: #afb1ba;
$light-gray-color: #ebebed;
$white-gray-color: #f7f7f7;
$dark-gray-color: #9b9da8;
$primary-color: #55d3ff;
$dark-primary-color: #1aaadc;
$secondary-color: #383c52;
$alternative-color: #134a63;
$alternative-color-2: #007fb7;
$alternative-color-3: #1581be;
$alternative-color-4: #3ac7f3;
$tooltip-color: #888a97;
$masterpass-orange-color: rgba(247, 158, 27, 0.1);
$light-background-color: #f8f8fb;
$system-green: #2eb755;

$input-color: $secondary-color;
$checkbox-label-color: #606375;
$separator-color: #f5f5f6;
$dark-separator-color: #adc2ca;
$disabled-color: #d7d8dc;
$placeholder-color: #c3c4cb;

$error-border-color: #ffabab;
$error-bg-color: rgba(255, 171, 171, 0.07);
$error-text-color: #f37268;

$toast-success: #ebf9ef;
$toast-success-text: #3ac562;
$toast-error: #ffeeee;
$toast-error-text: #ff5959;

$tiny-device-width: 639px;
$small-device-width: 959px;
$regular-device-width: 1199px;
$big-device-width: 1599px;
$large-device-width: 2100px;

$tiny-device-height: 500px;

$small-gutter: 10px;
$regular-gutter: 20px;
$big-gutter: 30px;
$bigger-gutter: 40px;
$large-gutter: 50px;

$font-tiny: 9px;
$font-smaller: 12px;
$font-small: 14px;
$font-regular: 16px;
$font-regular-big: 18px;
$font-big: 24px;
$font-bigger: 32px;
$font-largest: 48px;

$radius-regular: 8px;
$radius-large: 12px;

$blue-card-shadow-color: #1581be;
$red-card-shadow-color: #ee3c2d;
$orange-card-shadow-color: #fcbe63;
$green-card-shadow-color: rgba(86, 196, 196, 1);
$gray-card-shadow-color: #5d606d;
$darkblue-card-shadow-color: #4359cd;
$city-card-shadow-color: #39a2e7;
$digital-card-shadow-color: #1547e1;

$blue-card-item-color: rgba(58, 199, 243, 0.2);
$red-card-item-color: rgba(255, 117, 106, 0.2);
$orange-card-item-color: rgba(252, 190, 99, 0.3);
$green-card-item-color: rgba(86, 196, 196, 0.2);
$gray-card-item-color: rgba(254, 194, 72, 0.2);
$darkblue-card-item-color: rgba(55, 71, 158, 0.2);
$city-card-item-color: rgba(57, 162, 231, 0.2);
$digital-card-item-color: rgba(123, 155, 255, 0.2);
$plus-card-item-color: rgba(20, 166, 166, 0.3);

$card-detail-header-linear-red-1: #ff756a;
$card-detail-header-linear-red-2: #ee3c2d;
$card-detail-header-linear-green-1: #56c4c4;
$card-detail-header-linear-green-2: #179b92;
$card-detail-header-linear-orange-1: #fec248;
$card-detail-header-linear-orange-2: #f47524;
$card-detail-header-linear-darkblue-1: #1999c0;
$card-detail-header-linear-darkblue-2: #0e6292;
$card-detail-header-linear-blue-1: #3ac7f3;
$card-detail-header-linear-blue-2: #1581be;
$card-detail-header-linear-city-1: #39a2e7;
$card-detail-header-linear-city-2: #0072bc;
$card-detail-header-linear-digital-1: #7b9bff;
$card-detail-header-linear-digital-2: #1547e1;

$card_colors-blue-1: #1581be;
