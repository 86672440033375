@import 'index.scss';

#cardTransactions {
  max-width: 960px;
  padding-left: $big-gutter;
  margin-left: auto;
  margin-right: auto;
  margin-top: $big-gutter;
  @include regular-device {
    padding-left: 25px;
  }
  @include tiny-device {
    padding-left: $small-gutter;
    padding-right: $small-gutter;
  }
  .cardTransactions {
    &__container {
      max-width: 560px;
      @include small-device {
        max-width: 520px;
      }
    }
    &__see--transactions {
      border: 2px solid $separator-color;
      padding: 15px 9px 17px 9px;
      margin-top: $small-gutter;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      border-radius: 14px;
      cursor: pointer;
      font-weight: bold;
      font-size: $font-regular;
      margin-left: auto;
      margin-right: auto;
    }
    &__last-transactions {
      margin-top: $large-gutter;
    }
    &__red {
      svg {
        color: $red-card-shadow-color;
      }
    }
    &__blue {
      svg {
        color: $blue-card-shadow-color;
      }
    }
    &__green {
      svg {
        color: $green-card-shadow-color;
      }
    }
    &__orange {
      svg {
        color: $orange-card-shadow-color;
      }
    }
    &__darkblue {
      svg {
        color: $darkblue-card-shadow-color;
      }
    }
    &__city {
      svg {
        color: $city-card-shadow-color;
      }
    }
    &__digital {
      svg {
        color: $digital-card-shadow-color;
      }
    }
  }
}
