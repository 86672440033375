@import 'index.scss';

#contactPermission {
  width: 550px;
  margin: 0 auto;
  @include tiny-device {
    width: auto;
  }

  .contactPermission {
    &__title {
      margin-bottom: $large-gutter;
      @include small-device {
        margin-bottom: $big-gutter;
      }
      @include tiny-device {
        margin-bottom: $regular-gutter;
      }
    }
    &__button {
      margin-bottom: 32px;

      @include tiny-device {
        margin-bottom: 22px;
      }
    }
    &__checkBorder {
      padding: 12px 16px 12px 16px;
      border-radius: $radius-large;
      border: 2px solid $separator-color;
      cursor: pointer;
      .Mui-checked {
        .MuiSvgIcon-root {
          fill: $primary-color;
          border-radius: 6px !important;
        }
      }
      .MuiSvgIcon-root {
        fill: $disabled-color;
        border-radius: 6px !important;
      }
    }
  }
}
