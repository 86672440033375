@import 'index.scss';

#dialogWithActions {
  .dialogContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .card-settings {
    &__input {
      margin-top: $large-gutter;
      margin-bottom: $regular-gutter;
      text-align: left;
    }
  }
}
